// @ts-nocheck
/* eslint-disable */

const referenceContainer = document.querySelector<HTMLElement>('[data-mail-campaings-ref]')

if (! referenceContainer) {
    console.warn('No MailCampaigns reference container found')
} else {
    const reference = referenceContainer?.dataset.mailCampaingsRef!
    loadMailCampaignScript(reference)
}

function loadMailCampaignScript(referenceId: string): void {
    (function (d: Document, t: string) {
        const _mctc = {
            onload: function () {
                if (typeof this.mctrkDocumentLoad === 'function') {
                    this.mctrkDocumentLoad()
                }
            },
        }

        const s = d.getElementsByTagName(t)[0]
        const js = d.createElement(t) as HTMLScriptElement

        js.async = true
        js.src = 'https://interface.mailcampaigns.nl/interface/popup.nojquery.php?ref=' + referenceId

        s.parentNode?.insertBefore(js, s)
    })(document, 'script')
}
